import React, { useCallback, useEffect } from 'react'
import {
  getClearedPathname,
  is404Page,
  isMainPage,
  transliterate,
} from '../utils/functions'
import { useAppContext } from './AppContext'
import classNames from 'classnames'

const HeadingsNav = ({ location, withLegacyBanner }) => {
  const { currentVersion } = useAppContext()
  const pathnameWithoutLastCharSlash = location?.pathname?.replace(/(\/$)/, '')

  const languageIndex = pathnameWithoutLastCharSlash.search(
    /\/ru\/|\/en\/|\/pt\/|\/zh\//
  )
  const pathnameWithoutLanguage =
    languageIndex !== -1
      ? pathnameWithoutLastCharSlash.substring(languageIndex + 3)
      : ''

  const scroll = () => {
    let navList = document.getElementById('headings-nav')

    let headingsList = document.querySelectorAll(
      'h2[id], h3[id], h4[id], h5[id], h6[id]'
    )

    let headings = []

    for (let i = 0; i < headingsList.length; i++) {
      if (headingsList[i].parentNode.classList[0] !== 'note-block') {
        headings.push(headingsList[i])
      }
    }

    if (
      [
        '/bot_answers/message_types',
        '/bot_answers/reply_types',
        '/Patterns/base_patterns',
        '/Patterns/advanced_patterns',
      ].includes(pathnameWithoutLanguage)
    ) {
      headings = document.querySelectorAll('h2[id]')
    }

    let headingsPos = []
    for (let i = 0; i < headings.length; i++) {
      headingsPos.push(headings[i].offsetTop)
    }
    const offset = 100

    /*let activeListItem = headingsPos.findIndex(function(pos, index) {
        return window.pageYOffset >= pos - offset && window.pageYOffset < headingsPos[index + 1] - offset
    });*/

    let activeListItem = -1
    headingsPos.some(function (pos, index) {
      if (
        window.pageYOffset >= pos - offset &&
        window.pageYOffset < headingsPos[index + 1] - offset
      ) {
        activeListItem = index
        return true
      }
      return false
    })

    activeListItem =
      activeListItem === -1 && headingsPos.length !== 1
        ? window.pageYOffset > headingsPos[0] - offset
          ? headingsPos.length - 1
          : 0
        : document.body.scrollHeight === window.pageYOffset + window.innerHeight
        ? headingsPos.length - 1
        : activeListItem

    for (let i = 0; i < navList.childNodes.length; i++) {
      navList.childNodes[i].classList.remove('active')
    }

    if (navList.childNodes[activeListItem]) {
      navList.childNodes[activeListItem].classList.add('active')
    }
  }

  const buildHeadingsNav = (excludedHeadingsForPdf = null) => {
    window.removeEventListener('scroll', scroll)
    let navList = document.getElementById('pdf-headings-nav')
    if (!navList) navList = document.getElementById('headings-nav')

    let contentSection = document.getElementById('main')

    navList.removeEventListener('click', handleAnchorLinkClick)
    contentSection.removeEventListener('click', handleAnchorLinkClick)

    if (navList) {
      navList.innerHTML = ''
    }
    const markdownSection = document.querySelector('.markdown-section')

    let headingsList = markdownSection.querySelectorAll('h2, h3, h4, h5, h6')
    if (document.getElementById('pdf-headings-nav')) {
      let headings = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']
      if (excludedHeadingsForPdf) {
        headings = headings.filter(
          (heading) => !excludedHeadingsForPdf.includes(heading)
        )
      }
      const headingsSelector = headings?.join(', ')

      headingsList = markdownSection.querySelectorAll(headingsSelector)
      if (headings.includes('h1')) {
        headingsList = Array.prototype.slice.call(headingsList, 2)
      }
    }

    let anchorsToCheckDuplicates = []

    headingsList.forEach((h, i) => {
      const headingContentWrapperChildNodesCount = h.children.length

      const lastChildrenOfHeading =
        h.children[headingContentWrapperChildNodesCount - 1]

      const headingTag = lastChildrenOfHeading?.classList?.contains(
        'tag-heading'
      )
        ? lastChildrenOfHeading
        : null
      let headingText = headingTag
        ? h.textContent.replace(' ' + headingTag.textContent, '')
        : h.textContent

      // let link = h.firstChild?.textContent
      let link = headingText?.trim()?.toLowerCase()?.replaceAll(' ', '-')
      link = link.replaceAll(/[,.:()<>\[\]$|…*{}/?!@#%^&~]/g, '')

      if (link?.[0] && link?.[0].search(/[^0-9-]/) === -1) link = '_' + link

      const duplicateCount = anchorsToCheckDuplicates.filter(
        (anchor) => link === anchor
      )?.length
      anchorsToCheckDuplicates.push(link)

      if (duplicateCount > 0) link += `-${duplicateCount}`

      link = transliterate(link)

      if (!headingsList[i].id) {
        headingsList[i].id = link
      } else {
        link = headingsList[i].id
      }
      headingsList[
        i
      ].innerHTML = `<a href='#${link}' class='anchor'><span>${h.innerHTML}</span></a>`
    })
    let headings = []

    headingsList.forEach((heading) => {
      if (heading.parentNode.classList[0] !== 'note-block') {
        headings.push(heading)
      }
    })

    // Скопировать следующие три строчки
    // Подставить url нужной страницы без /#/docs/ru(en,zh)
    // Вписать уровни заголовков через запятую в формате h2[id]
    // Если нужно вообще без заголовков выводить, то headings = [];

    if (
      [
        '/bot_answers/message_types',
        '/bot_answers/reply_types',
        '/Patterns/base_patterns',
        '/Patterns/advanced_patterns',
      ].includes(pathnameWithoutLanguage)
    ) {
      headings = document.querySelectorAll('h2[id]')
    }
    if (['/platform_ux/authorization'].includes(pathnameWithoutLanguage)) {
      headings = []
    }

    if (headings.length > 1) {
      for (let i = 0; i < headings.length; i++) {
        let li = document.createElement('li')
        let a = document.createElement('a')
        a.href = '#' + headings[i].id

        const headingContentWrapper = headings[i].firstChild?.firstChild
        const headingContentWrapperChildNodesCount =
          headingContentWrapper.children.length

        const lastChildrenOfHeading =
          headingContentWrapper.children[
            headingContentWrapperChildNodesCount - 1
          ]

        const headingTag = lastChildrenOfHeading?.classList?.contains(
          'tag-heading'
        )
          ? lastChildrenOfHeading
          : null
        let linkText = headingTag
          ? headings[i].textContent.replace(' ' + headingTag.textContent, '')
          : headings[i].textContent

        a.innerText = linkText
        a.classList.add('anchor')
        li.appendChild(a)
        li.classList.add('heading-' + headings[i].tagName)
        if (navList) {
          navList.appendChild(li)
        }
      }
    }
    if (navList && headings.length > 1) {
      navList.childNodes[0].classList.add('active')
      window.addEventListener('scroll', scroll)
      navList.addEventListener('click', handleAnchorLinkClick)
      contentSection.addEventListener('click', handleAnchorLinkClick)
    }
  }

  const clearHeadingNav = () => {
    window.removeEventListener('scroll', scroll)
    let navList = document.getElementById('pdf-headings-nav')
    if (!navList) navList = document.getElementById('headings-nav')
    let contentSection = document.getElementById('main')

    navList.removeEventListener('click', handleAnchorLinkClick)
    contentSection.removeEventListener('click', handleAnchorLinkClick)

    if (navList) {
      navList.innerHTML = ''
    }
  }

  const scrollToAnchor = useCallback((rawTarget) => {
    const target = transliterate(rawTarget)
    const targetElement = document.querySelector(target?.toLowerCase())
    const offset = targetElement?.offsetTop

    setTimeout(() => {
      window.scrollTo({ top: offset - 40, behavior: 'smooth' })
      window.history.pushState(null, null, target?.toLowerCase())
    }, 0)
  }, [])

  const handleAnchorLinkClick = useCallback((e) => {
    const clickedElement = e.target

    if (
      clickedElement?.tagName === 'A' &&
      (clickedElement?.classList.contains('anchor') ||
        clickedElement.getAttribute('href')?.startsWith('#'))
    ) {
      e.preventDefault()
      const target = clickedElement.getAttribute('href')
      scrollToAnchor(decodeURI(target))
    }

    if (
      clickedElement?.parentNode?.tagName === 'A' &&
      (clickedElement?.parentNode?.classList.contains('anchor') ||
        clickedElement?.parentNode?.getAttribute('href')?.startsWith('#'))
    ) {
      e.stopPropagation()
      e.preventDefault()
      const target = clickedElement.parentNode.getAttribute('href')
      scrollToAnchor(decodeURI(target))
    }
  }, [])

  useEffect(() => {
    const pathname = getClearedPathname(location?.pathname, currentVersion)
    if (!isMainPage(pathname) && !is404Page(pathname)) {
      buildHeadingsNav()
    } else {
      clearHeadingNav()
    }
    window.buildHeadingsNav = buildHeadingsNav
  }, [pathnameWithoutLastCharSlash])

  useEffect(() => {
    if (location?.hash && !location.hash.includes('/docs/')) {
      // scrollToAnchor(decodeURI(location?.hash))
      setTimeout(() => scrollToAnchor(decodeURI(location?.hash)), 16)
    } else {
      const content = document.querySelector('.content')
      content?.scrollTo({ top: 0 })
    }
  }, [location?.pathname])

  return (
    <ul
      className={classNames('headings-nav', {
        'with-legacy-banner': withLegacyBanner,
      })}
      id="headings-nav"
    />
  )
}

export default React.memo(HeadingsNav)
