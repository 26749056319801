import React from 'react'
import classNames from 'classnames'
import { useAppContext } from './AppContext'

const Spinner = ({ fullScreen }) => {
  const { loading } = useAppContext()

  if (fullScreen && !loading) return null

  return (
    <div className={classNames('spinner-wrap', { 'full-screen': fullScreen })}>
      {fullScreen && <div className="spinner-overlay" />}
      <div className="spinner">
        <div className="circle" />
      </div>
    </div>
  )
}

export default React.memo(Spinner)
