import React from 'react'
import config from '../utils/config'
import { useAppContext } from './AppContext'

const Footer = () => {
  const { footerMenu } = config
  const { language } = useAppContext()
  const menuByLanguage = footerMenu?.[language]
  return menuByLanguage ? (
    <div className="footer">
      {menuByLanguage?.menuLeft && (
        <div className="footer-menu footer-menuLeft">
          <ul>
            {menuByLanguage?.menuLeft?.length > 0 &&
              menuByLanguage?.menuLeft.map((menuItem) => (
                <li key={`menuItem_${menuItem.name}`}>
                  <a
                    href={menuItem.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{menuItem.name}</span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      )}
      <div className="footer-menu footer-menuRight">
        <ul>
          {menuByLanguage?.menuRight?.length > 0 &&
            menuByLanguage?.menuRight.map((menuItem) => (
              <li key={`menuItem_${menuItem.name}`}>
                <a
                  href={menuItem.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={`far ${menuItem.icon}`} />
                  <span>{menuItem.name}</span>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </div>
  ) : null
}

export default React.memo(Footer)
