import React, { Component } from 'react'
import { useAppContext } from './AppContext'
import { logo } from '../utils/config'

const Logo = () => {
  const { currentVersion, versions, language } = useAppContext()

  const link =
    currentVersion === 'NoVersion' || currentVersion === versions?.actualVersion
      ? `/docs/${language}/`
      : `/${currentVersion}/docs/${language}/`

  return (
    <div className="app-name">
      <a className="app-name-link" href={link}>
        <img style={{ margin: 0 }} src={logo?.default} />
      </a>
    </div>
  )
}

export default React.memo(Logo)
