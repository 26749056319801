import React from 'react'
import TabController from './TabController'
import TableController from './TableController'

const ControllerComponent = ({ location }) => {
  return (
    <>
      <TabController location={location} />
      <TableController location={location} />
    </>
  )
}

export default React.memo(ControllerComponent)
