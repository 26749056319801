import { navigate } from 'gatsby'
import config from './config'
import { useEffect, useRef } from 'react'

const tranlittedLetters = {
  Ё: 'YO',
  Й: 'I',
  Ц: 'TS',
  У: 'U',
  К: 'K',
  Е: 'E',
  Н: 'N',
  Г: 'G',
  Ш: 'SH',
  Щ: 'SCH',
  З: 'Z',
  Х: 'H',
  Ъ: '',
  ё: 'yo',
  й: 'i',
  ц: 'ts',
  у: 'u',
  к: 'k',
  е: 'e',
  н: 'n',
  г: 'g',
  ш: 'sh',
  щ: 'sch',
  з: 'z',
  х: 'h',
  ъ: '',
  Ф: 'F',
  Ы: 'I',
  В: 'V',
  А: 'a',
  П: 'P',
  Р: 'R',
  О: 'O',
  Л: 'L',
  Д: 'D',
  Ж: 'ZH',
  Э: 'E',
  ф: 'f',
  ы: 'i',
  в: 'v',
  а: 'a',
  п: 'p',
  р: 'r',
  о: 'o',
  л: 'l',
  д: 'd',
  ж: 'zh',
  э: 'e',
  Я: 'Ya',
  Ч: 'CH',
  С: 'S',
  М: 'M',
  И: 'I',
  Т: 'T',
  Ь: '',
  Б: 'B',
  Ю: 'YU',
  я: 'ya',
  ч: 'ch',
  с: 's',
  м: 'm',
  и: 'i',
  т: 't',
  ь: '',
  б: 'b',
  ю: 'yu',
}

export const getClearedPathname = (path, version = 'NoVersion') => {
  let pathname = path
  if (pathname?.includes('/docs/')) {
    const docsIndex = pathname.indexOf('/docs/')
    pathname = path.substring(docsIndex)
  } else if (version !== 'NoVersion') {
    pathname = path.replace(`${version}/`, '')
  }

  return pathname
}

export const redirectFromOldLink = (location, language, setCurrentLanguage) => {
  const { hash } = location
  const pathname = getClearedPathname(location.pathname)
  let newPathname = pathname
  let newHash = ''

  if (hash.startsWith('#/docs')) {
    const oldHashIndex = hash.indexOf('?id=')
    const endIndex = oldHashIndex === -1 ? hash.length : oldHashIndex

    newPathname = hash.substring(1, endIndex)
    newPathname = newPathname.replace(/(docs\/.*?).md/, (match, p1) => p1)

    if (oldHashIndex !== -1) {
      newHash = hash.substring(oldHashIndex)
      newHash = newHash.replace('?id=', '#')
      newHash = decodeURI(newHash)
    }

    if (newPathname.slice(-1) !== '/') newPathname += '/'

    const newLanguage = getAndSetLanguageFromUrl(newPathname, language)

    if (newLanguage !== language) {
      setCurrentLanguage(newLanguage)
    }

    return navigate(newPathname + newHash)
  }

  if (pathname === '/') return navigate(`/docs/${language}/`)
}

export const getVersionFromUrl = (pathname) => {
  let version = 'NoVersion'
  const pathnameArray = pathname?.split('/')
  if (pathnameArray?.length > 0 && pathnameArray[2] === 'docs') {
    version = pathnameArray[1]
  }
  return version
}

export const disableRoutingForExcludedLinks = () => {
  const excludedLinksWrapper = document.querySelector('.excluded-links')

  excludedLinksWrapper?.addEventListener('click', (e) => {
    const target = e.target
    if (target.classList.contains('exclude') && target.href) {
      window.location.href = target.href
    }
  })
}

export const isMainPage = (pathname) => {
  const languages = config.languages
  let mainPagePathnames = ['/']
  if (languages)
    languages.forEach((language) =>
      mainPagePathnames.push(`/docs/${language}/`)
    )

  return mainPagePathnames.includes(pathname)
}

export const is404Page = (pathname) => {
  return pathname?.includes('/404')
}

export const prefixMainPagePaths = (version, actualVersion) => {
  if (version !== 'NoVersion' && actualVersion && version !== actualVersion) {
    const mainPage =
      document.querySelector('.main-page') ||
      document.querySelector('.hero-page__main')
    const mainPageLinks = mainPage?.querySelectorAll('a')
    mainPageLinks?.forEach((link) => {
      link.href = `/${version}${link.getAttribute('href')}`
    })
  }
}

export function usePrevious(value) {
  const ref = useRef()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

/**
 * Adapted from https://github.com/docsifyjs/docsify/blob/develop/src/core/render/compiler/image.js
 */
export const setImageAttrsFromDocsifyProps = () => {
  const article = document?.querySelector('.markdown-section')
  const images = article?.querySelectorAll('img') ?? []

  images.forEach((img) => {
    const { title, config } = getAndRemoveDocsifyConfig(img.title)
    img.title = title || img.alt

    if (config['no-zoom']) {
      img.setAttribute('data-no-zoom', '')
    }

    if (config.size) {
      const [width, height] = config.size.split('x')

      if (width.endsWith('%')) {
        img.style.width = width
        img.style.height = 'auto'
      } else {
        img.style.width = `${width}px`
        img.style.height = height ? `${height}px` : 'auto'
      }

      if (img.parentNode?.classList?.contains('gatsby-resp-image-wrapper')) {
        img.parentNode.style.maxWidth = `${width}px`
      }
    }
  })
}

/**
 * Borrowed from https://github.com/docsifyjs/docsify/blob/develop/src/core/render/utils.js
 */
const getAndRemoveDocsifyConfig = (str = '') => {
  const config = {}

  if (str) {
    str = str
      .replace(/^('|")/, '')
      .replace(/('|")$/, '')
      .replace(/(?:^|\s):([\w-]+:?)=?([\w-%]+)?/g, (m, key, value) => {
        if (key.indexOf(':') === -1) {
          config[key] = (value && value.replace(/&quot;/g, '')) || true
          return ''
        }

        return m
      })
      .trim()
  }

  return { str, config }
}

export const getAndSetLanguageFromUrl = (pathname, language) => {
  let newLanguage = language
  let langFromUrlIndex = pathname.search(/\/ru\/|\/en\/|\/pt\/|\/zh\//)
  let langFromUrl = pathname.substring(
    langFromUrlIndex + 1,
    langFromUrlIndex + 3
  )
  if (langFromUrlIndex !== -1 && langFromUrl) {
    newLanguage = langFromUrl
    localStorage.USER_LANGUAGE = newLanguage
  }

  return newLanguage
}

export const getInnerTextFromHtmlNode = (node, fromLink = false) => {
  let link = node.innerText

  const childNodes = fromLink
    ? node?.childNodes?.[0]?.childNodes?.[0]?.childNodes
    : node?.childNodes

  if (childNodes?.length > 0) {
    let parsedText = ''
    childNodes.forEach((childNode) => {
      if (!childNode?.classList?.contains('tag-heading')) {
        parsedText += childNode.innerText || childNode.wholeText
      }
    })

    link = parsedText
  }

  return link
}

export const transliterate = (word) => {
  return word
    .split('')
    .map((char) => {
      return tranlittedLetters[char] ?? char
    })
    .join('')
}
