import React, { PureComponent } from 'react'
import { AppContextProvider } from './AppContext'
import config from '../utils/config'
import Header from './Header'
import Sidebar from './Sidebar'

import Spinner from './Spinner'
import Content from './Content'
import ToTopButton from './ToTopButton'
import ControllerComponent from './ControllerComponent'
import LegacyBanner from './LegacyBanner'

class Layout extends PureComponent {
  render() {
    const { children, location } = this.props
    const { showLegacyBanner: withLegacyBanner } = config

    return (
      <AppContextProvider location={location}>
        <Spinner fullScreen />
        {withLegacyBanner && <LegacyBanner />}
        <Header location={location} withLegacyBanner={withLegacyBanner} />
        <Sidebar location={location} />
        <Content
          content={children}
          location={location}
          withLegacyBanner={withLegacyBanner}
        />
        <ControllerComponent location={location} />
        <ToTopButton location={location} />
      </AppContextProvider>
    )
  }
}

export default Layout
