import axios from 'axios'

export const getAlgoliaCredentials = () => {
  return axios.get('/algolia/algoliaCredentials')
}

export const getCurrentVersion = (version) => {
  const prefix = version === 'NoVersion' ? '' : `/${version}`
  return axios.get(`${prefix}/.version`)
}

export const getVersions = () => {
  return axios.get('/versions.json')
}
