import React, { useCallback, useEffect } from 'react'

const TableController = ({ location }) => {
  const wrapTables = useCallback(() => {
    const markdownTable = document.querySelectorAll('.markdown-section table')
    if (markdownTable?.length > 0) {
      markdownTable.forEach((table) => {
        const tableWrapper = document.createElement('div')
        tableWrapper.classList.add('table-wrap')
        table.parentNode.insertBefore(tableWrapper, table)
        tableWrapper.appendChild(table)
      })
    }
  }, [])

  useEffect(() => {
    wrapTables()
  }, [location?.pathname])

  return null
}

export default React.memo(TableController)
