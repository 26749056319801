import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import { useAppContext } from './AppContext'

const Breadcrumbs = ({ type, pathname }) => {
  const { currentVersion, language, versions } = useAppContext()
  const [breadcrumbs, setBreadcrumbs] = useState([])

  const pathnameWithoutSlash = useMemo(
    () => (pathname.endsWith('/') ? pathname.slice(0, -1) : pathname),
    [pathname]
  )

  const mainPagePath = useMemo(
    () =>
      currentVersion === 'NoVersion' || currentVersion === versions?.actualVersion
        ? `/docs/${language}/`
        : `/${currentVersion}/docs/${language}/`,
    [currentVersion, language, versions?.actualVersion]
  )

  const addBreadcrumbs = useCallback(() => {
    const sidebar = document.querySelector('.sidebar-nav ul')
    const page = sidebar?.querySelector(`[href$="${pathnameWithoutSlash}"]`)

    if (page) {
      const nodes = [page]

      while (true) {
        const li = nodes[0]?.parentElement
        const ul = li?.parentElement
        const previousAnchor = ul?.previousElementSibling

        if (previousAnchor) {
          nodes.unshift(previousAnchor)
        } else {
          break
        }
      }

      setBreadcrumbs(
        nodes.map((node) => ({ text: node.textContent, path: node.href }))
      )
    } else {
      setBreadcrumbs([])
    }
  }, [pathnameWithoutSlash])

  useEffect(() => {
    const timeoutId = setTimeout(addBreadcrumbs, 16)
    return () => clearTimeout(timeoutId)
  }, [addBreadcrumbs])

  switch (type) {
    case 'page': {
      if (breadcrumbs.length < 1) {
        return null
      }

      return (
        <p className="breadcrumbs">
          <Link to={mainPagePath}>
            <i className="fa fa-home" />
          </Link>
          {breadcrumbs.map(({ text, path }, index) => (
            <React.Fragment key={`breadcrumb_item_${index}_${text}`}>
              <span className="sep">/</span>
              {path ? <Link to={path}>{text}</Link> : <strong>{text}</strong>}
            </React.Fragment>
          ))}
        </p>
      )
    }
    case 'search': {
      if (breadcrumbs.length < 2) {
        return null
      }

      return (
        <p className="breadcrumbs">
          {breadcrumbs.slice(0, -1).map(({ text }, index) => (
            <React.Fragment key={`breadcrumb_item_${index}_${text}`}>
              {index > 0 && <span className="sep">/</span>}
              <strong>{text}</strong>
            </React.Fragment>
          ))}
        </p>
      )
    }
    default: {
      return null
    }
  }
}

export default React.memo(Breadcrumbs)
