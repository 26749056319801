import React, { useCallback } from 'react'
import { useAppContext } from './AppContext'

const SidebarToggle = () => {
  const { isSidebarShown, setIsSidebarShown, theme } = useAppContext()

  const toggleSidebar = useCallback(() => {
    setIsSidebarShown(!isSidebarShown)
  }, [isSidebarShown, setIsSidebarShown])

  return (
    <button className="sidebar-toggle" onClick={toggleSidebar}>
      <div className="sidebar-toggle-button">
        {theme !== 'cloud' || (theme === 'cloud' && !isSidebarShown) ? (
          <>
            <span />
            <span />
            <span />
          </>
        ) : (
          closeIcon
        )}
      </div>
    </button>
  )
}

const closeIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6732 9.00033L14.4602 5.2133C14.6792 4.99428 14.6792 4.63885 14.4602 4.41947L13.5802 3.53951C13.3612 3.32049 13.0058 3.32049 12.7864 3.53951L8.99974 7.32689L5.21271 3.53986C4.99368 3.32084 4.63825 3.32084 4.41888 3.53986L3.53927 4.41947C3.32024 4.6385 3.32024 4.99393 3.53927 5.2133L7.3263 9.00033L3.53927 12.7874C3.32024 13.0064 3.32024 13.3618 3.53927 13.5812L4.41923 14.4612C4.63825 14.6802 4.99368 14.6802 5.21306 14.4612L8.99974 10.6738L12.7868 14.4608C13.0058 14.6798 13.3612 14.6798 13.5806 14.4608L14.4606 13.5808C14.6796 13.3618 14.6796 13.0064 14.4606 12.787L10.6732 9.00033Z"
      fill="#4B535B"
    />
  </svg>
)

export default React.memo(SidebarToggle)
