import React from 'react'
import { t } from '../localization'
import { useAppContext } from './AppContext'
import { getClearedPathname, isMainPage } from '../utils/functions'

const OldVersionTip = ({ location }) => {
  const { versions, currentVersion } = useAppContext()

  const clearedPathname = getClearedPathname(location.pathname, currentVersion)
  const excludePages = ['versions', '', 'release_notes', 'change', '404']
  const currentPage =
    clearedPathname.split('/')[3] ?? clearedPathname.split('/')[1]

  if (
    isMainPage(clearedPathname) ||
    clearedPathname.includes('/release/') ||
    excludePages.includes(currentPage) ||
    !versions ||
    !currentVersion ||
    (versions &&
      currentVersion &&
      [versions.actualVersion, versions.nextVersion].includes(
        currentVersion
      )) ||
    !(versions || currentVersion !== 'NoVersion')
  ) {
    return null
  }

  return (
    <div className="old-version excluded-links">
      <p className="old-version-tip">
        <i className="fa fa-clock" aria-hidden="true" />
        {t('OldVersionTip: Old version tip text')}
        <a className="exclude" href={clearedPathname}>
          {t('OldVersionTip: Old version tip link')}
        </a>
      </p>
    </div>
  )
}

export default React.memo(OldVersionTip)
