try {
  require(`./theme/styles/style.scss`)
} catch (e) {}

try {
  require(`./theme/styles/responsive.scss`)
} catch (e) {}

import './src/styles/code-highlight.scss'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
