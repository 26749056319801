import React, { useCallback, useEffect, useState } from 'react'
import Search from '../components/Search'
import { useAppContext } from './AppContext'
import config from '../utils/config'
import { t } from '../localization'
import { Link } from 'gatsby'
import Logo from './Logo'
import SidebarToggle from './SidebarToggle'
import ReactDOM from 'react-dom'
import classNames from 'classnames'

const Header = ({ location, withLegacyBanner }) => {
  const [searchPlace, setSearchPlace] = useState(null)
  const {
    setCurrentLanguage,
    language,
    currentVersion,
    algoliaCredentials,
  } = useAppContext()

  const setLanguage = useCallback(
    (language) => () => {
      setCurrentLanguage(language)
    },
    [setCurrentLanguage]
  )

  const { languages, headerMenu } = config

  useEffect(() => {
    const mainPageSearchPlace = document.querySelector('.search-portal')
    setSearchPlace(mainPageSearchPlace)
  }, [location?.pathname])

  return (
    <div
      className={classNames('headline', {
        'with-legacy-banner': withLegacyBanner,
      })}
    >
      <SidebarToggle />
      <Logo />
      {algoliaCredentials && !searchPlace && <Search />}
      {algoliaCredentials &&
        searchPlace &&
        ReactDOM.createPortal(<Search />, searchPlace)}
      {(headerMenu || languages?.length > 0) && (
        <div id="nav">
          {headerMenu[language]?.length > 0 &&
            headerMenu[language].map((menuItem) => (
              <nav
                key={`header_menu_${menuItem.name}`}
                className="app-nav no-badge"
              >
                <ul>
                  <li>
                    <Link to={menuItem.link}>
                      {menuItem.isVersionLink ? currentVersion : menuItem.name}
                    </Link>
                  </li>
                </ul>
              </nav>
            ))}
          {languages?.length > 1 && (
            <nav className="app-nav language-select no-badge">
              <ul>
                <li>
                  <span>
                    <i className="fas fa-globe" />
                  </span>
                  <ul>
                    {languages.map((language) => (
                      <li
                        key={`language_switch_${language}`}
                        onClick={setLanguage(language)}
                      >
                        <span>{t(`LanguageSwitch: ${language}`)}</span>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(Header)
