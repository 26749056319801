let config = {}
let logo = null
let favicon = null

try {
  config = require('../../config.json')
} catch (e) {}

try {
  if (config.logo) {
    logo = require(`../../static/media/${config.logo}`)
  }
} catch (e) {}

try {
  if (config.favicon) {
    favicon = require(`../../static/media/${config.favicon}`)
  }
} catch (e) {}

export { logo, favicon }

export default config
