import { mapKeys } from 'lodash'
import docLocalization from './localization.json'
const Localize = require('localize')

const localization = {
  'Search: placeholder': {
    eng: 'Search',
    pt: "Pesquisa",
    ru: 'Поиск',
    zh: '搜索',
  },
  'Search: not found': {
    eng: 'Nothing found',
    pt: 'Nada encontrado',
    ru: 'Ничего не найдено',
    zh: '没有结果',
  },
  'LanguageSwitch: ru': {
    eng: 'Русский',
    pt: 'Русский',
    ru: 'Русский',
    zh: 'Русский',
  },
  'LanguageSwitch: en': {
    eng: 'English',
    pt: 'English',
    ru: 'English',
    zh: 'English',
  },
  "LanguageSwitch: pt": {
    eng: "Português",
    ru: "Português",
    pt: "Português",
    zh: "Português",
  },
  'LanguageSwitch: zh': {
    eng: '中文',
    pt: '中文',
    ru: '中文',
    zh: '中文',
  },
  'LanguageSwitch: title': {
    eng: 'Language',
    pt: 'Idioma',
    ru: 'Язык',
    zh: '语言',
  },
  'OldVersionTip: Old version tip text': {
    eng: 'You are viewing an obsolete version of this page. ',
    pt: "Você está visualizando uma versão obsoleta desta página. ",
    ru: 'Вы просматриваете устаревшую версию этой страницы. ',
  },
  'OldVersionTip: Old version tip link': {
    eng: 'View the latest version.',
    pt: "Veja a versão mais recente.",
    ru: 'Просмотреть текущую версию.',
  },
  'Sidebar: app link text': {
    eng: 'Go to $[1]',
    pt: 'Acessar o $[1]',
    ru: 'Перейти в $[1]',
  },
  'MainPage: Popular articles title': {
    eng: 'Popular articles',
    pt: "Artigos populares",
    ru: 'Популярные статьи',
  },
  'Footer: privacy policy': {
    eng: 'Privacy policy',
    pt: "Política de privacidade",
    ru: 'Политика конфиденциальности',
  },
  'LegacyBanner:Text': {
    eng: 'This site is no longer updated.',
    pt: 'Este site não é mais atualizado.',
    ru: 'Этот сайт больше не обновляется.',
  },
  'LegacyBanner:LinkText': {
    eng: 'Go to new $[1] docs',
    pt: 'Acessar a nova documentação da $[1]',
    ru: 'Перейти к новой документации $[1]',
  },
  'LegacyBanner:Link': {
    eng: 'https://help.cloud.just-ai.com/en/',
    pt: 'https://help.cloud.just-ai.com/en/',
    ru: 'https://help.cloud.just-ai.com/',
  },
  'LegacyBanner:Link:Tovie': {
    eng: 'https://help.cloud.tovie.ai/',
    pt: 'https://help.cloud.tovie.ai/pt/',
    ru: 'https://help.cloud.tovie.ai/',
  },
}

const localize = new Localize(
  Object.keys(docLocalization).length > 0 ? docLocalization : localization
)

localize.addTranslations = (translate) => {
  mapKeys(translate, (key) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!localize.getTranslations().hasOwnProperty(key)) {
      localize.loadTranslations(translate)
    }
  })
}

localize.checkExistKey = (key) => {
  return key !== localize.translate(key)
}

localize.throwOnMissingTranslation(false)

export const { translate: t } = localize
export default localize
