import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { navigate } from 'gatsby'
import {
  getAlgoliaCredentials,
  getCurrentVersion,
  getVersions,
} from '../actions'
import localize from '../localization'
import {
  disableRoutingForExcludedLinks,
  getAndSetLanguageFromUrl,
  getClearedPathname,
  getVersionFromUrl,
  isMainPage,
  prefixMainPagePaths,
  redirectFromOldLink,
  setImageAttrsFromDocsifyProps,
  usePrevious,
} from '../utils/functions'
import config from '../utils/config'

export const AppContext = createContext({})

export const AppContextProvider = ({ children, location }) => {
  const [language, setLanguage] = useState(undefined)
  const [algoliaCredentials, setAlgoliaCredentials] = useState(null)
  const [loading, setLoading] = useState(true)
  const [currentVersion, setCurrentVersion] = useState('NoVersion')
  const [versions, setVersions] = useState(null)
  const [isSidebarShown, setIsSidebarShown] = useState(true)
  const theme = config?.theme || 'jaicp'
  const productName = config?.productName || 'Aimylogic'
  const languages = config?.languages

  const getAndSetAlgoliaCredentials = useCallback(() => {
    getAlgoliaCredentials().then(({ data }) => {
      const dataToArray = data.split('\n')
      if (data && !data.startsWith('<!DOCTYPE')) {
        setAlgoliaCredentials({
          appId: dataToArray[0],
          apiKey: dataToArray[1],
          searchIndex: dataToArray[2],
        })
      }
    })
  }, [])

  const getAndSetAllVersions = useCallback(() => {
    getVersions().then(({ data }) => {
      if (data && Object.keys(data).length > 0) {
        setVersions({
          actualVersion: data.currentVersion,
          nextVersion: data.nextVersion,
          allVersions: data.allVersions,
        })
      }
    })
  }, [])

  const getAndSetCurrentVersion = useCallback(() => {
    const versionFromUrl = getVersionFromUrl(location?.pathname)
    getCurrentVersion(versionFromUrl).then(({ data }) => {
      if (data && !data.startsWith('<!DOCTYPE')) {
        setCurrentVersion(data)
      }
    })
  }, [])

  const setCurrentLanguage = useCallback(
    (lang, disableNavigation = false) => {
      const acceptedLanguage = languages.includes(lang) ? lang : 'en'
      setLanguage(acceptedLanguage)
      localStorage['USER_LANGUAGE'] = acceptedLanguage

      localize.setLocale(acceptedLanguage === 'en' ? 'eng' : acceptedLanguage)
      const clearedPathname = getClearedPathname(
        location.pathname,
        currentVersion
      )
      if (!disableNavigation)
        navigate(
          clearedPathname.replace(
            /\/ru\/|\/en\/|\/pt\/|\/zh\//,
            `/${acceptedLanguage}/`
          )
        )
    },
    [language, location.pathname]
  )

  const getAndSetLanguage = useCallback(() => {
    let language = 'en'

    const browserLanguage = window.navigator.language.split('-')[0]
    if (['ru', 'pt', 'zh'].includes(browserLanguage)) language = browserLanguage

    if (localStorage.USER_LANGUAGE) {
      language = localStorage.USER_LANGUAGE
    } else {
      localStorage.USER_LANGUAGE = language
    }

    let langFromUrlIndex = location.pathname.search(
      /\/ru\/|\/en\/|\/pt\/|\/zh\//
    )
    let langFromUrl = location.pathname.substring(
      langFromUrlIndex + 1,
      langFromUrlIndex + 3
    )
    if (langFromUrlIndex !== -1 && langFromUrl) {
      language = langFromUrl
      localStorage.USER_LANGUAGE = language
    }

    language = getAndSetLanguageFromUrl(location.pathname, language)

    setCurrentLanguage(language)
    redirectFromOldLink(location, language, setCurrentLanguage)
  }, [setCurrentLanguage])

  let prevPathname = usePrevious(location.pathname)

  const handleHistoryBack = useCallback(() => {
    const clearedPrevPathname = getClearedPathname(prevPathname)
    const clearedPathname = getClearedPathname(location.pathname)
    const prevLangFromUrl = clearedPrevPathname?.split('/')?.[2]
    const langFromUrl = clearedPathname?.split('/')?.[2]
    if (prevLangFromUrl && langFromUrl && langFromUrl !== prevLangFromUrl) {
      setCurrentLanguage(langFromUrl, true)
    }
  }, [location.pathname])

  useEffect(() => {
    try {
      getAndSetAllVersions()
      getAndSetAlgoliaCredentials()
      getAndSetCurrentVersion()
    } catch (e) {}
    getAndSetLanguage()
    setLoading(false)
  }, [])

  useEffect(() => {
    disableRoutingForExcludedLinks()
    handleHistoryBack()
    setImageAttrsFromDocsifyProps()
    window.setImageAttrsFromDocsifyPropsInPdf = setImageAttrsFromDocsifyProps
    if (window.innerWidth <= 768) {
      setIsSidebarShown(false)
    }
  }, [location.pathname])

  useEffect(() => {
    isMainPage(getClearedPathname(location.pathname, currentVersion)) &&
      prefixMainPagePaths(currentVersion, versions?.actualVersion)
  }, [location.pathname, currentVersion, versions])

  return (
    <AppContext.Provider
      value={{
        language,
        setCurrentLanguage,
        algoliaCredentials,
        currentVersion,
        loading,
        setLoading,
        versions,
        setIsSidebarShown,
        isSidebarShown,
        theme,
        productName,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = () => useContext(AppContext)
