import React from 'react'
import { t } from '../localization'
import config from '../utils/config'
import { useAppContext } from './AppContext'
import { Link } from 'gatsby'

const PopularArticles = () => {
  const { language } = useAppContext()
  const { footer } = config
  const { popularArticles } = footer || {}

  if (!popularArticles?.[language]) return null
  const popularArticlesByLanguage = popularArticles?.[language]

  return (
    <div className="popular-block">
      <h2>{t('MainPage: Popular articles title')}</h2>
      <div className="popular-block-cols">
        {popularArticlesByLanguage.map((column, index) => (
          <div key={`popular_articles_column_${index}`} className="popular-col">
            {column?.map((article) => (
              <div
                key={`${article.href}_${article.name}`}
                className="popular-item"
              >
                <Link to={article.href}>{article.name}</Link>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(PopularArticles)
