import React from 'react'
import { t } from '../localization'
import config from '../utils/config'

const LegacyBanner = () => {
  const { brand = 'justai', productName } = config

  const href =
    brand === 'justai' ? t('LegacyBanner:Link') : t('LegacyBanner:Link:Tovie')

  const utm = [
    `utm_source=${productName.toLowerCase().replace(' ', '_')}`,
    'utm_medium=legacy_banner',
    'utm_campaign=cloud_docs_launch',
  ].join('&')

  const cloudName = brand === 'justai' ? 'Conversational Cloud' : 'Tovie Cloud'

  return (
    <p className="legacy-banner">
      <span>{t('LegacyBanner:Text')}</span>
      <a
        className="legacy-banner__link"
        href={`${href}?${utm}`}
        target="_blank"
        rel="noopener"
      >
        {t('LegacyBanner:LinkText', cloudName)}
      </a>
    </p>
  )
}

export default LegacyBanner
