import React, { useCallback, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useAppContext } from './AppContext'
import config from '../utils/config'
import classNames from 'classnames'
import { t } from '../localization'

const SIDEBAR_ITEM_HEIGHT = 70
const APP_LINK_BLOCK_HEIGHT = 80
const JAICP_SIDEBAR_PADDING_BOTTOM = 80

const Sidebar = ({ location }) => {
  const { language, isSidebarShown, theme, productName } = useAppContext()
  const { appLink, showLegacyBanner } = config
  const sidebarQuery = graphql`
    query Sidebar {
      ru: markdownRemark(fields: { slug: { eq: "/docs/ru/_sidebar/" } }) {
        html
      }
      en: markdownRemark(fields: { slug: { eq: "/docs/en/_sidebar/" } }) {
        html
      }
      pt: markdownRemark(fields: { slug: { eq: "/docs/pt/_sidebar/" } }) {
        html
      }
      zh: markdownRemark(fields: { slug: { eq: "/docs/zh/_sidebar/" } }) {
        html
      }
    }
  `
  const sidebar = useStaticQuery(sidebarQuery)

  const getAllParents = (node, parents) => {
    parents = parents || []
    if (
      node &&
      node.parentNode !== document.getElementsByClassName('sidebar-nav')[0]
    ) {
      parents.push(node.parentNode)
      return getAllParents(node.parentNode, parents)
    } else {
      return parents
    }
  }

  const markParents = (notCollapsedTarget = null) => {
    let elements = []
    if (document.getElementsByClassName('sidebar-nav')[0]) {
      let elems = document
        .getElementsByClassName('sidebar-nav')[0]
        .querySelectorAll('.sidebar-nav ul li')
      elements = []
      for (let i = 0; i < elems.length; i++) {
        if (elems[i].getElementsByTagName('ul').length > 0) {
          elements.push(elems[i])
          if (!notCollapsedTarget || elems[i] !== notCollapsedTarget)
            elems[i].classList.add('collapsed')
          elems[i].classList.add('parent')

          if (
            !elems[i].getElementsByClassName('collapse-arrow') ||
            (elems[i].getElementsByClassName('collapse-arrow') &&
              elems[i].getElementsByClassName('collapse-arrow').length === 0)
          ) {
            let collapseArrow = document.createElement('span')
            collapseArrow.classList.add('collapse-arrow')
            elems[i].prepend(collapseArrow)
          }
        }
      }

      let element = document
        .getElementsByClassName('sidebar-nav')[0]
        .getElementsByClassName('active')[0]
      if (element) {
        element.classList.remove('collapsed')
        let parents = getAllParents(element)
        for (
          let i = 0;
          i < parents.length && parents.indexOf(notCollapsedTarget) === -1;
          i++
        ) {
          parents[i].classList.remove('collapsed')
        }
        element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
      if (notCollapsedTarget) {
        let parents = getAllParents(notCollapsedTarget)
        for (let i = 0; i < parents.length; i++) {
          parents[i].classList.remove('collapsed')
        }
      }
    }
  }

  const removeP = () => {
    let sidebar = document.querySelectorAll('.sidebar-nav p')
    sidebar.forEach((el) => {
      el.parentNode && el.parentNode.insertBefore(el.childNodes[0], el)
      el.remove()
    })
  }

  const addSidebarClickEvents = useCallback(() => {
    document.addEventListener('click', function (e) {
      const target = e.target
      const targetParent = e.target.parentNode
      if (
        target.tagName === 'STRONG' &&
        targetParent.classList.contains('parent')
      ) {
        e.stopPropagation()
        targetParent.classList.toggle('collapsed')
      }
      if (
        target.tagName === 'A' ||
        (target.tagName === 'STRONG' && targetParent.tagName === 'A')
      ) {
        const currentItemLink = target.href || targetParent.href
        const currentPageUrl = window.location.href.replace(
          window.location.hash,
          ''
        )
        if (currentItemLink === currentPageUrl) {
          targetParent.tagName === 'A'
            ? targetParent.parentNode.classList.toggle('collapsed')
            : targetParent.classList.toggle('collapsed')
        }
      }
      if (
        target.classList.contains('collapse-arrow') &&
        targetParent.classList.contains('parent')
      ) {
        e.stopPropagation()
        targetParent.classList.toggle('collapsed')
      }
      if (target.tagName === 'STRONG' && targetParent.tagName === 'A') {
        const element = targetParent.parentNode
        scrollOnEndOfSidebar(element)
        return
      }
      if (target.tagName === 'STRONG' && targetParent.tagName === 'LI') {
        const element = targetParent
        scrollOnEndOfSidebar(element)
        return
      }
      if (target.tagName === 'A') {
        const element = target
        scrollOnEndOfSidebar(element)
      }
    })
  }, [])

  const scrollOnEndOfSidebar = (element) => {
    setTimeout(() => {
      const coords = element.getBoundingClientRect()
      const elementBottomCoords = coords.y + coords.height
      const sidebar = document.querySelector('aside.sidebar')

      if (
        elementBottomCoords +
          SIDEBAR_ITEM_HEIGHT +
          (theme === 'aimylogic'
            ? APP_LINK_BLOCK_HEIGHT
            : JAICP_SIDEBAR_PADDING_BOTTOM) >
        window.innerHeight
      ) {
        sidebar.scroll({
          top: sidebar.scrollTop + SIDEBAR_ITEM_HEIGHT,
          behavior: 'smooth',
        })
      }
    }, 0)
  }

  const markActive = () => {
    let { pathname } = location
    if (pathname[pathname.length - 1] === '/')
      pathname = pathname.substring(0, pathname.length - 1)
    let currentActiveLink = document.querySelector(`.sidebar-nav li.active`)
    let sidebarLink = document.querySelector(
      `.sidebar-nav li a[href="${pathname}"]`
    )

    currentActiveLink && currentActiveLink.classList.remove('active')
    sidebarLink && sidebarLink.parentNode.classList.add('active')

    let parents = []
    while (sidebarLink) {
      parents.unshift(sidebarLink)
      sidebarLink = sidebarLink.parentNode
    }
    parents?.length > 0 &&
      parents.forEach((parent, i) => {
        if (parent?.classList?.contains('parent') && parent.tagName === 'LI') {
          parents[i].classList.remove('collapsed')
        }
      })
  }

  useEffect(() => {
    removeP()
    markParents()
    markActive()
  }, [language])

  useEffect(() => {
    markActive()
  }, [location.pathname])

  useEffect(() => {
    addSidebarClickEvents()
  }, [])

  return (
    <aside
      className={classNames('sidebar', {
        'with-app-link': appLink,
        'with-legacy-banner': showLegacyBanner,
        hidden: !isSidebarShown,
      })}
    >
      <div className="sidebar-nav">
        <div dangerouslySetInnerHTML={{ __html: sidebar?.[language]?.html }} />
      </div>
      {appLink && (
        <div className="app-link-block">
          <div className="app-link">
            <a
              href={appLink}
              target="_blank"
              rel="noreferrer"
              dangerouslySetInnerHTML={{
                __html: t('Sidebar: app link text', productName),
              }}
            />
          </div>
        </div>
      )}
    </aside>
  )
}

export default React.memo(Sidebar)
