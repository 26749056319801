import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { getClearedPathname } from '../utils/functions'
import { useAppContext } from './AppContext'

const OFFSET_TO_SHOW_THE_BUTTON = 250

const ToTopButton = ({ location }) => {
  const [isShown, setIsShown] = useState(false)
  const { currentVersion } = useAppContext()
  const pathname = getClearedPathname(location?.pathname, currentVersion)

  const handleClick = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (pathname === '/') return
      setIsShown(window.scrollY > OFFSET_TO_SHOW_THE_BUTTON)
    })
  })

  return (
    <button
      className={classNames('back-to-top', { hidden: !isShown })}
      onClick={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 0 24 24"
        width="24"
      >
        <path
          stroke="currentColor"
          d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"
        />
        <path d="M0 0h24v24H0z" fill="none" />
      </svg>
    </button>
  )
}

export default ToTopButton
