// fork from docsify copy code

export const renderCopyCode = () => {
  const targetElms = document.querySelectorAll('pre[class*="language-"]')
  const i18n = {
    buttonText: 'Copy to clipboard',
    errorText: 'Error',
    successText: 'Copied',
  }

  const template = [
    '<button class="docsify-copy-code-button">',
    `<span class="label">${i18n.buttonText}</span>`,
    '</button>',
  ].join('')

  targetElms.forEach((elm) => {
    elm.insertAdjacentHTML('beforeend', template)
  })

  const listenerHost = document.querySelector('.markdown-section')

  listenerHost.addEventListener('click', (evt) => {
    const isCopyCodeButton =
      evt.target.classList.contains('docsify-copy-code-button') ||
      evt.target.parentNode.classList.contains('docsify-copy-code-button')

    if (isCopyCodeButton) {
      const buttonElm =
        evt.target.tagName === 'BUTTON' ? evt.target : evt.target.parentNode
      const range = document.createRange()
      const preElm = buttonElm.parentNode
      const codeElm = preElm.querySelector('code')
      let selection = window.getSelection()

      range.selectNode(codeElm)
      selection.removeAllRanges()
      selection.addRange(range)

      try {
        const successful = document.execCommand('copy')

        if (successful) {
          buttonElm.classList.add('success')
          setTimeout(function () {
            buttonElm.classList.remove('success')
          }, 1000)
        }
      } catch (err) {
        console.error(`docsify-copy-code: ${err}`)

        buttonElm.classList.add('error')
        setTimeout(function () {
          buttonElm.classList.remove('error')
        }, 1000)
      }

      selection = window.getSelection()

      if (typeof selection.removeRange === 'function') {
        selection.removeRange(range)
      } else if (typeof selection.removeAllRanges === 'function') {
        selection.removeAllRanges()
      }
    }
  })
}
