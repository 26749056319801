import React from 'react'
import config from '../utils/config'
import { useAppContext } from './AppContext'
import PopularArticles from './PopularArticles'
import { t } from '../localization'

const FooterAimylogic = () => {
  const { language } = useAppContext()
  const { footer } = config
  const { copyright, policy, menu, social } = footer[language] || {}

  return (
    <>
      <PopularArticles />

      <div className="footer">
        {menu && (
          <div className="footer-menu">
            <ul>
              {menu?.map((menuItem) => (
                <li key={`${menuItem.href}_${menuItem.name}`}>
                  <a href={menuItem.href}>{menuItem.name}</a>
                </li>
              ))}
            </ul>
          </div>
        )}
        {social && (
          <div className="footer-social">
            {social?.map((socialItem) => (
              <div
                key={`social_${socialItem.href}`}
                className="footer-social-item"
              >
                <a href={socialItem.href} target="_blank" rel="noreferrer">
                  <i className={`fab ${socialItem.icon}`} />
                </a>
              </div>
            ))}
          </div>
        )}
        {copyright && policy && (
          <div className="footer-copyright">
            {copyright}
            <a href={policy} target="_blank" rel="noreferrer">
              {t('Footer: privacy policy')}
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default React.memo(FooterAimylogic)
