import React, { useEffect, useState } from 'react'
import Breadcrumbs from './Breadcrumbs'
import HeadingsNav from './HeadingsNav'
import Footer from './Footer'
import { renderCopyCode } from '../utils/copy-code'
import OldVersionTip from './OldVersionTip'
import { is404Page, isMainPage as isMainPageCheck } from '../utils/functions'
import { useAppContext } from './AppContext'
import classNames from 'classnames'
import FooterAimylogic from './FooterAimylogic'

const Content = ({ content, location, withLegacyBanner }) => {
  const [isMainPage, setIsMainPage] = useState(false)
  const [isNotFoundPage, setIsNotFoundPage] = useState(false)
  const { isSidebarShown, theme } = useAppContext()

  useEffect(() => {
    renderCopyCode()
    setIsMainPage(isMainPageCheck(location?.pathname))
    setIsNotFoundPage(is404Page(location?.pathname))
  }, [location?.pathname])

  return (
    <>
      <section
        className={classNames('content', {
          'sidebar-hidden': !isSidebarShown,
          'main-page': isMainPage && theme === 'aimylogic',
          'page-404-wrap': isNotFoundPage,
          'with-legacy-banner': withLegacyBanner,
        })}
      >
        <article className="markdown-section" id="main">
          <Breadcrumbs type="page" pathname={location.pathname} />
          <OldVersionTip location={location} />
          {content}
        </article>
        <HeadingsNav location={location} withLegacyBanner={withLegacyBanner} />
        {theme === 'aimylogic' ? <FooterAimylogic /> : <Footer />}
      </section>
    </>
  )
}

export default Content
