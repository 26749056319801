import React, { useCallback, useEffect } from 'react'

const TabController = ({ location }) => {
  const setActiveCodeBlockTab = useCallback((e) => {
    const button = e.target
    const buttonParent = button.parentElement
    if (!button.classList.contains('active')) {
      const buttons = buttonParent.querySelectorAll(
        '.code-block-tabs__buttons-tab'
      )
      buttons.forEach((button) => button.classList.remove('active'))
      button.classList.add('active')
      const tabIndex = button.getAttribute('index')
      const codeBlockTabs = button.closest('.code-block-tabs')
      const tabContentArray = codeBlockTabs.querySelectorAll(
        '.code-block-tabs__content > div'
      )
      tabContentArray.forEach((tab, index) => {
        tab.classList.add('inactive')
        if (tabIndex == index) {
          tab.classList.remove('inactive')
        }
      })
    }
  }, [])

  const setEqualBlockHeight = useCallback((blocks, height) => {
    blocks.forEach((block) => (block.style.height = height + 'px'))
  }, [])

  const setCodeBlockHeightAndClickEvents = useCallback(() => {
    const codeBlockButtons = document.querySelectorAll(
      '.code-block-tabs__buttons-tab'
    )
    codeBlockButtons.forEach((button) => {
      button.addEventListener('click', setActiveCodeBlockTab)
    })

    const codeBlockTabsElems = document.querySelectorAll('.code-block-tabs')
    codeBlockTabsElems.forEach((codeBlockTabs) => {
      let tabContentArray = codeBlockTabs.querySelectorAll(
        '.code-block-tabs__content > div'
      )
      let maxHeight = 0
      tabContentArray.forEach((tab, index) => {
        if (tab.offsetHeight > maxHeight) {
          maxHeight = tab.offsetHeight
        }
        if (index !== 0) tab.classList.add('inactive')
      })
      setEqualBlockHeight(tabContentArray, maxHeight)
    })
  }, [])

  const removeButtonEvents = useCallback(() => {
    const codeBlockButtons = document.querySelectorAll(
      '.code-block-tabs__buttons-tab'
    )
    codeBlockButtons.forEach((button) => {
      button.removeEventListener('click', setActiveCodeBlockTab)
    })
  }, [])

  const rerenderTabsForPdf = useCallback(() => {
    const tabBlocks = document.querySelectorAll('.code-block-tabs')
    tabBlocks.forEach((tabBlock) => {
      const buttons = tabBlock.querySelectorAll('.code-block-tabs__buttons div')
      const contentBlocks = tabBlock.querySelectorAll(
        '.code-block-tabs__content div'
      )
      contentBlocks.forEach((contentBlock, index) => {
        contentBlock.parentElement?.insertBefore(buttons[index], contentBlock)
      })
    })
  }, [])

  useEffect(() => {
    window.rerenderTabsForPdf = rerenderTabsForPdf
    setCodeBlockHeightAndClickEvents()
    return () => removeButtonEvents()
  }, [location?.pathname])

  return null
}

export default React.memo(TabController)
